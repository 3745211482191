import React from 'react';
import { PDFViewer, Page, Document, Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    container: {
        width: '100%', // Ensure the PDF viewer occupies the full width of its container
        height: '90vh', // Set the height to occupy 90% of the viewport height
    },
    page: {
        flexDirection: 'row',
        backgroundColor: '#ffffff'
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    table: {
        display: 'table',
        width: '100%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderCollapse: 'collapse',
    },
    tableRow: {
        margin: 'auto',
        flexDirection: 'row',
        padding: 5,
        borderStyle: 'solid',
        borderWidth: 1,
    },
    tableCell: {
        margin: 'auto',
        padding: 5,
    },
    printButton: {
        marginTop: 20,
        textAlign: 'center',
    }
});

const PDFPreviewComponent = ({ edited, members }) => {
    // Function to calculate the total contribution amount


    return (
        <div style={styles.container}>
            <PDFViewer style={styles.container}>
                <Document>
                    <Page size="A4" style={styles.page}>
                        <View style={styles.section}>
                            <Text>Contribution Details</Text>
                            <View style={styles.table}>
                                <View style={styles.tableRow}>
                                    <Text style={styles.tableCell}>S.N</Text>
                                    <Text style={styles.tableCell}>Member Name</Text>
                                    <Text style={styles.tableCell}>Contribution Amount</Text>
                                </View>
                                {members?.map((member, index) => (
                                    <View key={member.id} style={styles.tableRow}>
                                        <Text style={styles.tableCell}>{index + 1}</Text>
                                        <Text style={styles.tableCell}>{member?.insuree?.otherNames} {member?.insuree?.lastName}</Text>
                                        {/* Change 'contributionPaidByFamily' to the appropriate property */}
                                        <Text style={styles.tableCell}>{member?.contributionPaidByFamily}</Text>
                                    </View>
                                ))}
                                <View style={styles.tableRow}>
                                    <Text style={styles.tableCell}></Text>
                                    <Text style={styles.tableCell}>Total</Text>
                                    <Text style={styles.tableCell}>121212</Text>
                                </View>
                            </View>
                        </View>
                    </Page>
                </Document>
            </PDFViewer>

        </div>
    );
};

export default PDFPreviewComponent;
