import React from 'react';
import {View, StyleSheet } from '@react-pdf/renderer';
import Invoice_Header_Table from './Invoice_Header_Table'
import Invoice_Table_Row from "./Invoice_Table_Row";
import InvoiceTableBlankSpace from './InvoiceTableBlankSpace'
import Invoice_Table_Footer from './Invoice_Table_Footer'

const tableRowsCount = 1;

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        borderWidth: 1,
        borderColor: '#bff0fd',
    },
});

  const Invoice_Items_Table = ({invoice, edited}) => (
    <View style={styles.tableContainer}>
        <Invoice_Header_Table />
        <Invoice_Table_Row items={invoice.items} edited={edited}/>
        <InvoiceTableBlankSpace rowsCount={1} />
        <Invoice_Table_Footer items={invoice.items} edited={edited} />
    </View>
  );
  
  export default Invoice_Items_Table