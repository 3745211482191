import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';

const styles = {
  root: {
    width: '100%',
    marginTop: 20,
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
};

class MemberTable extends React.Component {
  render() {
    const { classes, members, edited } = this.props;

    const totalCoverage = members?.reduce((acc, member) => {
      if (member?.insuree) {
        const contribution = parseFloat(edited?.contributionPaidForEachMember);
        return acc + (contribution || 0);
      }
      return acc;
    }, 0);
    return (
      <div className={classes.root}>
        <Typography variant="h6" gutterBottom>
          Household Members
        </Typography>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>SN</TableCell>
              <TableCell>Member Name</TableCell>
              <TableCell>Head</TableCell>
              <TableCell>Insurance Fund ({edited?.contributionPaidForEachMember ?? 0})</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {members?.map((member, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>
                  {`${member?.insuree?.otherNames} ${member?.insuree?.lastName}`}
                </TableCell>
                <TableCell>
                  {member?.insuree?.head ? <StarIcon /> : 'No'}
                </TableCell>
                <TableCell>{edited.contributionPaidForEachMember ?? 0}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={3} align="right">
                Total Coverage:
              </TableCell>
              <TableCell>{totalCoverage ?? 0}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    );
  }
}

export default withStyles(styles)(MemberTable);
