import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        backgroundColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
    },
    description: {
        width: '33%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    qty: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    rate: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    amount: {
        width: '15%'
    },
  });

  const Invoice_Header_Table = ({ household }) => {
    if (true) {
      return (
        <View style={styles.container}>
          <Text style={styles.description}>Contributorr</Text>
          <Text style={styles.description}>Government</Text>
          <Text style={styles.amount}>Total</Text>
        </View>
      );
    } else {
      return (
        <View style={styles.container}>
          <Text style={styles.description}>Item Description</Text>
          <Text style={styles.qty}>Qty</Text>
          <Text style={styles.rate}>@</Text>
          <Text style={styles.amount}>Amount</Text>
        </View>
      );
    }
  };
  
  
  
  export default Invoice_Header_Table