import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Container from '@material-ui/core/Container';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PDFPreviewComponent from './PdfPreviewComponent';
import Invoice from './receipt/Invoice';
import invoiceData from './receipt/invoiceData';
import {PDFViewer} from '@react-pdf/renderer'

const PreviewModal = ({ open, handleClose, edited, members }) => {
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      fullWidth={!isSmallScreen}
      maxWidth={!isSmallScreen ? 'md' : 'xs'}
    >
      <DialogContent style={{overflowY: "clip"}}>
          {/* <PDFPreviewComponent edited={edited} members={members}/> */}
          <PDFViewer width="1600" height="1000" className="app" >
            <Invoice
             invoice={invoiceData}
             members={members}
             edited={edited} 
            />
          </PDFViewer>
      </DialogContent>
    </Dialog>
  );
};

export default PreviewModal;
