import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  headerContainer: {
    marginTop: 36
  },
  Bill_To: {
    marginTop: 20,
    paddingBottom: 3,
    fontFamily: 'Helvetica-Oblique'
  },
});

const Bill_To = ({ invoice, members }) => {
  // Filter members where head is true
  //debugger
  const headMembers = members?.filter(member => member?.insuree.head);

  return (
    <View style={styles.headerContainer}>
      <Text style={styles.Bill_To}>Household Representative:</Text>
      <View>
        {headMembers?.map(member => (
          <View key={member.id}>
            <Text>{member?.insuree?.otherNames} {member?.insuree?.lastName}</Text>
          </View>
        ))}
      </View>
    </View>
  );
};

export default Bill_To;
