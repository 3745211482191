import React, { Fragment } from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    Invoice_NoContainer: {
        flexDirection: 'row',
        marginTop: 20,
        justifyContent: 'flex-end'
    },
    invoiceDateContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    invoiceDate: {
            fontSize: 12,
            fontStyle: 'bold',
    },
    label: {
        width: 60
    }
    
  });


  const Invoice_No = ({invoice}) => (
        <Fragment>
            <View style={styles.Invoice_NoContainer}>
                <Text style={styles.label}>Invoice No: </Text>
                <Text style={styles.invoiceDate}>{Math.floor((Math.random() * 10000) + 1)}</Text>
            </View >
            <View style={styles.invoiceDateContainer}>
                <Text style={styles.label}>Date:</Text>
                <Text >{Math.floor((Math.random() * 10000) + 1)}</Text>
            </View >
        </Fragment>
  );
  
  export default Invoice_No