import React, {Fragment} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
    },
    description: {
        width: '60%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    qty: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    rate: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    amount: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
    },
  });


  const totalPremium = (edited, members) => {
    return parseFloat(parseFloat(edited?.contributionPaidForEachMember ?? 0 ) * parseFloat(members?.length ?? 0.0));
  }
  const InvoiceTableRow = ({ items, household, edited, members }) => {
    if (household) {
      // Return different view if household is true
      return (
        <View style={styles.householdRow}>
          
        </View>
      );
    } else {
      // Return the regular rows if household is false or undefined
      const rows = members?.map(item => (
        <View style={styles.row} key={item.id}>
          <Text style={styles.description}>{item?.insuree?.otherNames} {item?.insuree?.lastName}</Text>
          <Text style={styles.qty}>{1}</Text>
          <Text style={styles.rate}>{edited?.contributionPaidForEachMember ?? 0}</Text>
          <Text style={styles.amount}>{totalPremium(edited, members)}</Text>
        </View>
      ));
  
      return <>{rows}</>;
    }
  };
export default InvoiceTableRow