import React from "react";
import { ConstantBasedPicker } from "@openimis/fe-core";

import { SCHEME_TYPE_PICKER } from "../constants";

const SchemeTypePicker = (props) => {
  return (
    <ConstantBasedPicker
      module="claim"
      label="scheme"
      constants={SCHEME_TYPE_PICKER}
      {...props}
    />
  );
};

export default SchemeTypePicker;