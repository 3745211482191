import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 20,
    },
    section: {
        width: '45%',
        textAlign: 'center',
    },
    line: {
        borderBottom: '1pt solid black',
        marginBottom: 20,
    },
});

const SignatureLine = () => (
    <View style={styles.line} />
);

const SignatureContent = ({ title }) => (
    <View style={styles.section}>
        <Text>{title}</Text>
        <SignatureLine />
    </View>
);

const SignatureSection = () => (
    <>
        <SignatureContent title="Signed by Household" />
        <View style={{ width: '20%' }}></View>
        <SignatureContent title="Signed by Government" />
    </>
);

export default SignatureSection;
