import React from 'react';
import { Page, Document, Image, StyleSheet } from '@react-pdf/renderer';
import InvoiceTitle from './InvoiceTitle';
import BillTo from './BillTo'
import InvoiceNo from './InvoiceNo'

import InvoiceItemsTable from './InvoiceItemsTable'
import InvoiceThankYouMsg from './InvoiceThankYouMsg'


import Bill_to from "../receipt_hospital/Bill_to";
import Invoice_No from "../receipt_hospital/Invoice_No"
import Invoice_Items_Table from "../receipt_hospital/Invoice_Items_Table";
import Ititle from "../receipt_hospital/Ititle";
import Header_Header_Table from "../receipt_hospital/Invoice_Header_Table";
import Invoice_thank_You_Msg from '../receipt_hospital/Invoice_thank_You_Msg';
import SignatureSection from "../receipt_hospital/SignatureSection";
// import logo from '../../../src/logo.png'


const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft: 60,
        paddingRight: 60,
        lineHeight: 1.5,
        flexDirection: 'column',
    },
    logo: {
        width: 74,
        height: 66,
        marginLeft: 'auto',
        marginRight: 'auto'
    }
});

const Invoice = ({ invoice, edited, members }) => {
    return (
        <Document>
            <Page size="A5" style={styles.page}>
                {/* <Image style={styles.logo} src="https://image.similarpng.com/very-thumbnail/2021/08/Web-Icon-Summary-and-Sample-Logo-on-transparent-background-PNG.png" /> */}
                <Ititle title='Contribution Slip by HouseHold' household={true} />
                <InvoiceNo invoice={Math.floor((Math.random() * 10000) + 1)} />
                <BillTo invoice={invoice} members={members} household={true} />
                <Invoice_Items_Table invoice={invoice} edited={edited}  household={true} />
                <SignatureSection />
            </Page>
            <Page size="A4" style={styles.page}>
                {/* <Image style={styles.logo} src="https://image.similarpng.com/very-thumbnail/2021/08/Web-Icon-Summary-and-Sample-Logo-on-transparent-background-PNG.png" /> */}
                <InvoiceTitle title='Insurance premium subsidy' household={false} />
                <InvoiceNo  edited={edited} invoice={Math.floor((Math.random() * 10000) + 1)} />
                <BillTo invoice={invoice} members={members}  edited={edited} household={false}/>
                <InvoiceItemsTable invoice={invoice} edited={edited} members={members} household={false}/>
                <InvoiceThankYouMsg />
            </Page>
        </Document>
    );
};


export default Invoice