import React, {Fragment} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
    },
    description: {
        width: '33%',
        textAlign: 'center',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    qty: {
        width: '33%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        paddingRight: 8,
    },
    rate: {
        width: '33%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        paddingRight: 8,
    },
    amount: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
    },
  });

  const TotalContribution = (edited) => {
    return (parseFloat(edited?.contributionPaidByFamily ?? 0 ) * 2);
  }

  const Invoice_Table_Row = ({ items, household, edited, members }) => {
    if (false) {
      // Return different view if household is true
      return (
        // Your different view goes here
        <View style={styles.householdRow}>
          
        </View>
      );
    } else {
      // Return the regular rows if household is false or undefined
      return(
        <View style={styles.row}>
          <Text style={styles.description}>{edited?.contributionPaidByFamily ?? 0}</Text>
          <Text style={styles.qty}>{edited?.contributionPaidByFamily ?? 0}</Text>
          <Text style={styles.rate}>{TotalContribution(edited)}</Text>
        </View>
      );
    
      // return <>{}</>;
    }
  };
export default Invoice_Table_Row