import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { bindActionCreators } from "redux";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { Grid, Button } from "@material-ui/core";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import StarIcon from '@material-ui/icons/Star';
import ListSubheader from '@material-ui/core/ListSubheader';
import FamilyMembersContribution from "./FamilyMembersContribution";
import PDFPreviewComponent from "./PdfPreviewComponent";
import PreviewModal from "./PreviewModel";
import invoiceData from "../components/receipt/invoiceData";
import Invoice from "../components/receipt/Invoice";
import { PDFViewer } from '@react-pdf/renderer'

import {
  updateContributionPaidByGov
  // policiesByInsuree
} from "../actions";
import {
  withHistory,
  withModulesManager,
  AmountInput,
  TextInput,
  ValidatedTextInput,
  PublishedComponent,
  formatMessageWithValues,
  formatMessage,
  FormPanel,
  WarningBox,
  ProgressOrError,
  ControlledField
} from "@openimis/fe-core";
import {
  validateReceipt,
  clearReceiptValidation,
  setReceiptValid,
  printContribution
} from "../actions";

const styles = (theme) => ({
  tableTitle: theme.table.title,
  item: theme.paper.item,
  fullHeight: {
    height: "100%",
  },
});

class ContributionCbhiPanel extends FormPanel {


  constructor(props) {
    super(props);
    this.state = {
      showPDFPreview: false
    };
  }

  handlePreviewClick = () => {
    this.setState({ showPDFPreview: true });
  };


  componentDidMount() {
    const lastUUID = window.location.href.split('/').pop(); //quick , need update
    // console.log("policy", lastUUID);
    this.props.policiesByInsuree(lastUUID);


  }

  _onChange = (attr, value) => {
    let edited = { ...this.props.edited, [attr]: value };
    this.props.onEditedChanged(edited);

  };
  componentDidUpdate(prevProps) {
    // Check if members prop has changed
    if (prevProps.members !== this.props.members) {
    }
  }

  updateAmountPaid = (c) => {
    this._onChange("contributionPaidForEachMember", c)
    this.props.updateContributionPaidByGov(c);
  }

  handleClosePreview = () => {
    this.setState({ showPDFPreview: false });
  };

  openRegistrationSlip = (edited) => {
    
    this.props.printContribution(window.location.href.split('/').pop(), edited);
  }
  render() {
    const {
      classes,
      edited,
    } = this.props;
    const { showPDFPreview } = this.state;


    return (
      <>
        <Grid container className={classes.item} style={{ backgroundColor: 'pink' }}>
          <Grid item xs={3} className={classes.item}>
            <AmountInput
              module='contribution'
              // label='contribution.amount'
              label='Amount Paid by Household'
              required
              readOnly={
                false
              }
              value={edited?.contributionPaidByFamily}
              displayZero={true}
              onChange={(d) => this._onChange("contributionPaidByFamily", d)}
            />
          </Grid>

          <Grid item xs={3} className={classes.item}>
            <AmountInput
              module='contribution'
              // label='contribution.amount'
              label='Amount Paid by Government'
              required
              readOnly={
                true
              }
              value={edited?.contributionPaidByFamily}

              displayZero={true}
            // onChange={(c) => this.updateAttribute("contributionPaidByGov", c)}
            />
          </Grid>
          <Grid item xs={3} className={classes.item}>
            <AmountInput
              module='contribution'
              // label='contribution.amount'
              label='Combined Fund'
              required
              readOnly={
                true
              }
              value={parseFloat(parseFloat(edited?.contributionPaidByFamily) + parseFloat(edited?.contributionPaidByFamily))}

              displayZero={true}
            // onChange={(c) => this.updateAttribute("contributionPaidByGov", c)}
            />
          </Grid>

          <Grid item xs={3} className={classes.item}>
            <AmountInput
              module='contribution'
              // label='contribution.amount'
              label='Amount Funded by Government for each member'
              required
              readOnly={
                false
              }
              value={edited?.contributionPaidForEachMember ?? 0}

              displayZero={true}
              // onChange={(c) => this.updateAttribute('fundedAmount', c)}
              onChange={(c) => this.updateAmountPaid(c)}
            />
          </Grid>
          <ProgressOrError
            progress={this.props.fetchingMembers}
            error={this.props.errorMembers}
          />
          <FamilyMembersContribution members={this.props.members} edited={edited} />
          <Button onClick={this.handlePreviewClick}>Preview PDF</Button>
          <Button onClick={()=>this.openRegistrationSlip(edited)}>Generate Receipt Slip </Button>

        </Grid>
        <PreviewModal
          open={showPDFPreview}
          handleClose={this.handleClosePreview}
          edited={edited}
          members={this.props.members}
        />
        <hr />
      </>
    );
  }
}



const mapStateToProps = (store, props) => ({
  members: store?.contribution?.members,
  fetchingMembers: store?.contribution?.fetchingMembers,
  errorMembers: store?.contribution?.errorMembers,
  paidContributionGov: store?.contribution?.paidContributionGov


});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateContributionPaidByGov,
      printContribution
    },
    dispatch
  );
};

export default withModulesManager(
  withHistory(
    injectIntl(
      connect(mapStateToProps, mapDispatchToProps)(
        withTheme(withStyles(styles)(ContributionCbhiPanel))
      )
    )
  )
);
